import React, { useEffect, useState } from "react";
import "./index.scss";
import Layer from "../../components/Layer";
import { toast } from "react-toastify";

import getProvidersPagination from "../../services/get-providers";
import ListProviders from "../../components/ListProviders";
import Lottie from "react-lottie";
import { defaultOptionsLoading } from "../../assets/lottie-animations/animationOptions";
import searchProvider from "../../services/post-search-provider";

function SuppliersScreen() {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [modalProvidersIsOpen, setModalProvidersIsOpen] = useState(false);
  const [listProviders, setListProviders] = useState<any>([]);
  const [currentProvider, setCurrentProvider] = useState<any>(null);
  const [editMode, setEditMode] = useState(false);
  const [deletePermission, setDeletePermission] = useState(false);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(5);
  const [totalePages, setTotalePages] = useState(1);
  const [query, setQuery] = useState("");
  const [loading, setLoading] = useState(false);
  const closeAddProviderModal = () => {
    setModalProvidersIsOpen(false);
    setDeletePermission(false);
    setEditMode(false);
    setCurrentProvider(null);
  };

  const doGetProviders = (page: any, limit: any) => {
    setLoading(true);
    getProvidersPagination(page, limit)
      .then((response: any) => {
        setLoading(false);

        if (response?.data?.success) {
          setListProviders([...listProviders, ...response?.data?.content]);
          setTotalePages(response?.data?.pagination?.totalPages);
        } else {
          toast.error(`Error : ${response?.data?.error}`);
        }
      })
      .catch((error) => {
        setLoading(false);
        toast.error(error);
      });
  };
  const doGetProvidersInit = (page: any, limit: any) => {
    setLoading(true);
    getProvidersPagination(page, limit)
      .then((response: any) => {
        setLoading(false);

        if (response?.data?.success) {
          setPage(1);

          setListProviders(response?.data?.content);
          setTotalePages(response?.data?.pagination?.totalPages);
        } else {
          toast.error(`Error : ${response?.data?.error}`);
        }
      })
      .catch((error) => {
        setLoading(false);

        toast.error(error);
      });
  };

  useEffect(() => {
    if (!Boolean(query)) {
      doGetProvidersInit(1, 5);
    }
  }, [query]);

  console.log(listProviders);

  const loadMore = () => {
    const newPage = page + 1;
    setPage(page + 1);
    doGetProviders(newPage, limit);
  };

  const doHandleSearchProvider = (query: any) => {
    searchProvider(query)
      .then((response) => {
        if (response?.data?.success) {
          setListProviders(response?.data.content);
        } else {
          toast?.error(response?.data?.message);
        }
      })
      .catch((error) => {
        toast.error(error);
      });
  };
  return (
    <Layer
      title={"Fournisseurs"}
      modalIsOpen={modalIsOpen}
      setModalIsOpen={setModalIsOpen}
      handleSearchSubmit={doHandleSearchProvider}
      setQuery={setQuery}
    >
      {loading ? (
        // @ts-ignore
        <Lottie options={defaultOptionsLoading} height={"60%"} width={"30%"} />
      ) : (
        <ListProviders
          listProviders={listProviders}
          setIsOpen={setModalProvidersIsOpen}
          setCurrentProvider={setCurrentProvider}
          productId={currentProvider?._id}
          setEditMode={setEditMode}
          loadMore={loadMore}
          totalePages={totalePages}
          page={page}
          query={query}
        />
      )}
    </Layer>
  );
}

export default SuppliersScreen;
