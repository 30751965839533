import React, { useEffect } from "react";

import { Routes, Route, Navigate } from "react-router-dom";
import { BrowserRouter as Router } from "react-router-dom";

import "boxicons/css/boxicons.min.css";
import "react-toastify/dist/ReactToastify.css";
import HomeScreen from "./screens/HomeScreen";
import AchatScreen from "./screens/AchatScreen";
import PurchaceDetails from "./screens/PurchaceDetails";
import ProtectedOut from "./layout/ProtectedOut";
import LoginScreen from "./screens/login-screen/LoginScreen";
import ConfirmationScreen from "./screens/confirmation-screen/ConfirmationScreen";
import ProtectedIn from "./layout/ProtectedIn";
import SuppliersScreen from "./screens/suppliers-screen/SuppliersScreen";
import { getFromLocalStorage, setLocalStorage } from "./utils/localstorage";
import { toast } from "react-toastify";

function App() {
  function handleMessageFromWeb(event: any) {
    try {
      const { action, key, value } = event.data || "";

      const token = getFromLocalStorage("token") || "";

      if (
        !token &&
        action &&
        action === "SET_LOCAL_STORAGE" &&
        key &&
        key === "token"
      ) {
        setLocalStorage(key, value);
      }
    } catch (error) {
      toast.error("Something wrong in message");
    }
  }

  function handleEventFromMobile(message: any) {
    try {
      const { action, key, value } = message?.data || {};

      const token = getFromLocalStorage("token") || "";

      if (
        !token &&
        action &&
        action === "SET_LOCAL_STORAGE" &&
        key &&
        key === "token"
      ) {
        setLocalStorage(key, value);
        window.location.reload();
      }
    } catch (error) {
      toast.error("Something wrong in event");
    }
  }

  useEffect(() => {
    window.addEventListener("message", handleMessageFromWeb);
    return () => {
      window.removeEventListener("message", handleMessageFromWeb);
    };
  }, []);

  React.useEffect(() => {
    document.addEventListener("message", handleEventFromMobile);

    return () => document.removeEventListener("message", handleEventFromMobile);
  }, []);

  return (
    <Router>
      <Routes>
        <Route
          path="/login"
          element={
            <ProtectedIn>
              <LoginScreen />
            </ProtectedIn>
          }
        />
        <Route
          path="/confirmation"
          element={
            <ProtectedIn>
              <ConfirmationScreen />
            </ProtectedIn>
          }
        />
        <Route
          path="/home"
          element={
            <ProtectedOut>
              <HomeScreen />
            </ProtectedOut>
          }
        />

        <Route
          path="/suppliers"
          element={
            <ProtectedOut>
              <SuppliersScreen />
            </ProtectedOut>
          }
        />
        <Route
          path="/achat"
          element={
            <ProtectedOut>
              <AchatScreen />
            </ProtectedOut>
          }
        />
        <Route
          path="/purchaceDetails/:id"
          element={
            <ProtectedOut>
              <PurchaceDetails />
            </ProtectedOut>
          }
        />
        <Route path="*" element={<Navigate to="/home" replace />} />
      </Routes>
    </Router>
  );
}

export default App;
