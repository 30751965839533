import React, { useState } from "react";
import "./index.scss";
import { useLocation, useNavigate } from "react-router-dom";
import Modal from "react-modal";
import { LocalStorageEnum } from "../../utils/localstorage/LocalStorageEnum";
import { deleteFromLocalStorage } from "../../utils/localstorage";

const customModalStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    background: `linear-gradient(269deg, #F1FFFC 0%, #FFFCF7 100%), #FFF`,
    padding: "5vh",
    borderRadius: "10px",
    textAlign: "center",
    margin: "0rem 1px",
  },
};

function SideBar() {
  const sideBarElements = [
    {
      title: "Acceuil ",
      value: "",
      route: "/home",
      icon: "providerIcon",
    },
    {
      title: "providers",
      value: "",
      route: "/suppliers",
      icon: "providerIcon",
    },
    { title: "achat", value: "", route: "/achat", icon: "achatIcon" },
  ];
  const location = useLocation();

  const navigate = useNavigate();

  const [modalIsOpen, setIsOpen] = useState<boolean>(false);
  function openModal() {
    setIsOpen(true);
  }
  function closeModal() {
    setIsOpen(false);
  }

  const handleDisconnect = () => {
    deleteFromLocalStorage(LocalStorageEnum.TOKEN);
    deleteFromLocalStorage(LocalStorageEnum.PHONENUMBER);
    navigate(`/login`);
  };

  return (
    <div className="container-sb">
      {/* @ts-ignore */}
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        contentLabel="Example Modal"
        ariaHideApp={false}
        // @ts-ignore
        style={customModalStyles}
        shouldCloseOnEsc
        preventScroll
      >
        <p
          style={{
            fontSize: "16px",
            fontWeight: "700",
            color: "#000000",
          }}
        >
          Voulez vous vraiment se déconnecter ?
        </p>
        <div
          style={{
            display: "flex",
            justifyContent: "space-around",
            marginTop: "50px",
            marginBottom: "20px",
            gap: "20px",
            alignItems: "center",
            cursor: "pointer",
            fontSize: "16px",
            fontWeight: "700",
            color: "#ffffff",
            borderRadius: "5px",
            padding: "10px 20px",
            border: "none",
            outline: "none",
          }}
        >
          <button
            style={{
              outline: "none",
              background: "#999999",
              color: "white",
              border: "none",
              padding: "1.5vh 1.5vh",
              borderRadius: "5px",
              fontSize: "12px",
              fontWeight: "600",
            }}
            onClick={closeModal}
          >
            Annuler
          </button>
          <button
            style={{
              outline: "none",
              background: "#B6CC8B",
              color: "#000000",
              border: "none",
              padding: "1.5vh 1.5vh",
              borderRadius: "5px",
              fontSize: "12px",
              fontWeight: "600",
            }}
            onClick={() => handleDisconnect()}
          >
            Confirmer
          </button>
        </div>
      </Modal>

      <div className="logo-container-sb">
        <img src="./../../assets/achatAppIcon.png" alt="logo" />
        <div className="title-container">
          <span className="title-style">Pizzaie</span>
          <span className="title-style">Achat</span>
        </div>
      </div>
      <div className="elements-sb">
        {sideBarElements?.map((element, index) => (
          <button
            key={index}
            className={`${
              location.pathname === element.route
                ? "btn-element-sb-active"
                : "btn-element-sb"
            }`}
            onClick={() => navigate(element?.route)}
          >
            <img
              src={`./../../assets/providerIcon.png`}
              alt="storeIcon"
              className="img-btn-sb"
            />
            {element?.title}
          </button>
        ))}
      </div>
      <div className="disconnect-container">
        <div className="disconnect-button" onClick={openModal}>
          Se Déconnecter
        </div>
      </div>
    </div>
  );
}

export default SideBar;
