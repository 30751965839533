import React, { useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import getPurchaceById from "../../services/get-purchace-by-id";
import { capitalizeFirstLetter } from "../../utils";
import "./index.scss";
import Select from "react-select";

import Modal from "react-modal";

import Layer from "../../components/Layer";
import getProvidersPagination from "../../services/get-providers";
import productAssignment from "../../services/put-product-assignment";
import EditProdPurchase from "../../components/Modals/EditProdPurchase";

const dot = (color = "transparent"): any => ({
  alignItems: "center",
  display: "flex",

  ":before": {
    backgroundColor: color,
    borderRadius: 10,
    content: '" "',
    display: "block",
    marginRight: 8,
    height: 10,
    width: 10,
  },
});
const customStylesModal = {
  content: {
    top: "45%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    height: "40vh",
    minHeight: "40vh",
    width: "40vw",
    minWidth: "380px",
    borderWidth: 0.2,
    borderRadius: 12,

    background: `linear-gradient(0deg, #FFF 0%, #FFF 100%), #FFF`,
  },
  overlay: {
    backgroundColor: "rgba(0, 0, 0, 0.5)", // Change the background color and opacity here
  },
};
const circleStyles: any = {
  pending: { color: "#FFE6A6" },
  completed: { color: "#DBF2DA" },
  cancelled: { color: "#FFCDCD" },
};

const styleInput = () => {
  let colourStyles = {};
  return (colourStyles = {
    control: (styles: any) => ({ ...styles, backgroundColor: "white" }),
    option: (styles: any, { data, isDisabled, isFocused, isSelected }: any) => {
      return {
        ...styles,
        backgroundColor: isDisabled
          ? undefined
          : isSelected
          ? data.color
          : isFocused
          ? "#ddd"
          : undefined,
        color: isDisabled ? "#ccc" : "#000",
        cursor: isDisabled ? "not-allowed" : "default",

        ":active": {
          ...styles[":active"],
          backgroundColor: !isDisabled
            ? isSelected
              ? data.color
              : "#fd44"
            : undefined,
        },
      };
    },
    input: (styles: any) => ({ ...styles, ...dot() }),
    placeholder: (styles: any) => ({ ...styles, ...dot("#ccc") }),
    singleValue: (styles: any, { data }: any) => ({
      ...styles,
    }),
  });
};
function PurchaceDetails() {
  const [showMenu, setShowMenu] = useState(false);
  const { id } = useParams();
  const location = useLocation();
  const [listProviders, setListProviders] = useState([]);
  const state = location.state || {};
  const [listProduct, setListProduct] = useState<any>([]);
  const [orderNumber, setOrderNumber] = useState<any>(null);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [productToEdit, setProductToEdit] = useState<any>(null);
  const closeModal = () => {};

  const doGetPurchaceById = () => {
    getPurchaceById(id)
      .then((response) => {
        if (response?.data?.status === 500) {
          toast.error("internal server error !");
          return;
        }
        if (!response?.data?.success) {
          toast.error(response?.data?.message);
          return;
        }
        if (response?.data?.success) {
          setListProduct(response?.data?.content?.products);
          setOrderNumber(response?.data?.content?.name);
        }
      })
      .catch((error) => {
        toast.error("error", error);
      });
  };

  const doGetListProviders = () => {
    getProvidersPagination(1, 50)
      .then((response) => {
        if (response?.data?.success) {
          const providers = response?.data?.content.map((provider: any) => ({
            value: provider?._id,
            label: provider?.fullname,
          }));

          setListProviders(providers);
        }
      })
      .catch((error) => {
        toast.error("error", error);
      });
  };

  const handleOptionSelect = (selectedOption: any, prd: any) => {
    console.log("Selected provider:", selectedOption, prd);
    const data = { supplier: selectedOption?.value };
    productAssignment(prd?._id, id, data)
      .then((response) => {
        if (response?.data?.success) {
          toast.success("product assigned successfully !");
          doGetPurchaceById();
        } else {
          toast.error(response?.data?.message);
        }
      })
      .catch((error) => {
        toast.error("error", error);
      });

    // Handle the selected provider option here
  };

  useEffect(() => {
    doGetPurchaceById();
    doGetListProviders();
  }, []);
  console.log(listProduct);

  let itemName = "";
  const onConfirm = () => {};

  const onClose = () => {
    setModalIsOpen(false);
  };

  const openEdit = (prd: any) => {
    setProductToEdit(prd);
    setModalIsOpen(true);
  };
  return (
    <Layer title={orderNumber}>
      <div className="purchace-detail-body">
        <div className="table-container-pcd">
          <table className="table-style">
            <thead style={{ height: 60 }}>
              <tr>
                <th className="title-txt">item</th>
                <th className="title-txt">Qte</th>
                <th className="title-txt">Poids</th>
                <th className="title-txt">PU</th>
                <th className="title-txt">Fournisseur</th>
                <th className="title-txt"></th>
              </tr>
            </thead>
            <tbody style={{ paddingTop: 20 }}>
              {listProduct?.length > 0 ? (
                listProduct?.map((prd: any, index: any) => (
                  <tr key={index} className="line-table-style">
                    <td className="td-right">
                      <p className="td-right-text-style">
                        <p style={{ height: "10%" }}>{prd?.name}</p>
                      </p>
                    </td>
                    <td className="td-center">
                      <p className="td-center-txt"> {prd?.quantity}</p>
                    </td>
                    <td className="td-center">
                      <p className="td-center-txt">
                        {prd?.portion} {prd?.product?.unit}
                      </p>
                    </td>
                    <td className="td-left">
                      <p className="td-left-txt">{prd?.cost} DT</p>
                    </td>

                    <td className="td-left" style={{ padding: 10 }}>
                      <Select
                        classNamePrefix="select"
                        value={{
                          value: prd?.supplier?._id,
                          label: prd?.supplier?.fullname,
                        }}
                        options={listProviders}
                        styles={styleInput()}
                        onChange={(selectedOption) =>
                          handleOptionSelect(selectedOption, prd)
                        }
                      />
                    </td>
                    <td className="td-left" style={{ padding: 10 }}>
                      <button className="btn-edit-prod-purchase"> 
                        <i
                          className="bx bx-edit"
                          style={{ fontSize: 24 }}
                          onClick={() => openEdit(prd)}
                        ></i>
                      </button>
                    </td>
                  </tr>
                ))
              ) : (
                <></>
              )}
            </tbody>
          </table>
        </div>
      </div>
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        style={customStylesModal}
        contentLabel="Example Modal"
        ariaHideApp={false}
      >
        <EditProdPurchase
          itemName={productToEdit?.name}
          unitPrice={productToEdit?.cost}
          initialQuantity={productToEdit?.quantity}
          onClose={onClose}
          orderId={id}
          product={productToEdit}
          doGetPurchaceById={doGetPurchaceById}
        />
      </Modal>
    </Layer>
  );
}

export default PurchaceDetails;
