import customEnv from "./envCustom.json";

type envConfType = {
  INVENTORY_SERVER_URL: string;
  IS_DEV: boolean;
  SECRET_KEY: string;
  INVENTORY_SOCKET_URL: string;
  LB4_SERVER_URL: string;
};
const INVENTORY_SERVER_URL =
  customEnv.INVENTORY_SERVER_URL || process.env.REACT_APP_SERVER_URL || "";

const INVENTORY_SOCKET_URL =
  customEnv.INVENTORY_SOCKET_URL || process.env.REACT_APP_SOCKET_URL || "";

const LB4_SERVER_URL =
  customEnv.LB4_SERVER_URL || process.env.REACT_APP_SERVER_LB4_URL || "";

const IS_DEV =
  (customEnv.IS_DEV || process.env.REACT_APP_IS_DEV)?.toUpperCase() === "TRUE"
    ? true
    : false;

const SECRET_KEY =
  customEnv.SECRET_KEY || process.env.REACT_APP_SECRET_KEY || "";

export const envconf: envConfType = {
  INVENTORY_SERVER_URL,
  IS_DEV,
  SECRET_KEY,
  INVENTORY_SOCKET_URL,
  LB4_SERVER_URL,
};

export default envconf;
