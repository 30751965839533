// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.container-sb {
  height: 100%;
  width: 100%;
  background-color: #f8ffe9;
}
.container-sb .logo-container-sb {
  height: 20vh;
  width: 100%;
  display: flex;
  align-items: center;
  padding-left: 20px;
}
.container-sb .logo-container-sb .title-container {
  display: flex;
  flex-direction: column;
  margin-left: 15px;
}
.container-sb .logo-container-sb .title-container .title-style {
  font-size: 22px;
  font-weight: 700;
}
.container-sb .elements-sb {
  display: flex;
  flex-direction: column;
  margin-top: 15px;
}
.container-sb .elements-sb .btn-element-sb {
  border-width: 0px;
  margin: 10px;
  min-height: 60px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 10px;
  border-radius: 9px;
}
.container-sb .elements-sb .btn-element-sb .img-btn-sb {
  margin-left: 5px;
  margin-right: 15px;
}
.container-sb .elements-sb .btn-element-sb-active {
  border-width: 2px;
  margin: 10px;
  min-height: 60px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 10px;
  border-radius: 9px;
  background-color: #d4e3b7;
  border-color: #b6cc8b;
}
.container-sb .elements-sb .btn-element-sb-active .img-btn-sb {
  margin-left: 5px;
  margin-right: 15px;
}`, "",{"version":3,"sources":["webpack://./src/components/SideBar/index.scss"],"names":[],"mappings":"AAAA;EACE,YAAA;EACA,WAAA;EACA,yBAAA;AACF;AAAE;EACE,YAAA;EACA,WAAA;EAEA,aAAA;EACA,mBAAA;EACA,kBAAA;AACJ;AAAI;EACE,aAAA;EACA,sBAAA;EACA,iBAAA;AAEN;AADM;EACE,eAAA;EACA,gBAAA;AAGR;AAEE;EACE,aAAA;EACA,sBAAA;EACA,gBAAA;AAAJ;AACI;EACE,iBAAA;EACA,YAAA;EACA,gBAAA;EACA,aAAA;EACA,2BAAA;EACA,mBAAA;EACA,aAAA;EACA,kBAAA;AACN;AAAM;EACE,gBAAA;EACA,kBAAA;AAER;AACI;EACE,iBAAA;EACA,YAAA;EACA,gBAAA;EACA,aAAA;EACA,2BAAA;EACA,mBAAA;EACA,aAAA;EACA,kBAAA;EACA,yBAAA;EACA,qBAAA;AACN;AAAM;EACE,gBAAA;EACA,kBAAA;AAER","sourcesContent":[".container-sb {\n  height: 100%;\n  width: 100%;\n  background-color: #f8ffe9;\n  .logo-container-sb {\n    height: 20vh;\n    width: 100%;\n    // background-color: aquamarine;\n    display: flex;\n    align-items: center;\n    padding-left: 20px;\n    .title-container {\n      display: flex;\n      flex-direction: column;\n      margin-left: 15px;\n      .title-style {\n        font-size: 22px;\n        font-weight: 700;\n      }\n    }\n  }\n\n  .elements-sb {\n    display: flex;\n    flex-direction: column;\n    margin-top: 15px;\n    .btn-element-sb {\n      border-width: 0px;\n      margin: 10px;\n      min-height: 60px;\n      display: flex;\n      justify-content: flex-start;\n      align-items: center;\n      padding: 10px;\n      border-radius: 9px;\n      .img-btn-sb {\n        margin-left: 5px;\n        margin-right: 15px;\n      }\n    }\n    .btn-element-sb-active {\n      border-width: 2px;\n      margin: 10px;\n      min-height: 60px;\n      display: flex;\n      justify-content: flex-start;\n      align-items: center;\n      padding: 10px;\n      border-radius: 9px;\n      background-color: #d4e3b7;\n      border-color: #b6cc8b;\n      .img-btn-sb {\n        margin-left: 5px;\n        margin-right: 15px;\n      }\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
