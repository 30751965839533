import React, { useLayoutEffect, useState } from "react";
import SideBar from "../SideBar";
import "./index.scss";
import Header from "../Header";
import { useWindowDimensions } from "../../utils";
import Menu from "../Menu";

function Layer({ children, title, modalIsOpen, setModalIsOpen , handleSearchSubmit , setQuery }: any) {
  const { height, width } = useWindowDimensions();
  const [showMenu, setShowMenu] = useState(false);
  const toggleMenu = () => {
    setShowMenu(!showMenu);
  };
  return (
    <div className={ "high-content"}>
      <div className="side-bar-container">
        {width > 768 ? (
          <SideBar />
        ) : (
          <i className="bx bx-menu" style={{fontSize : 26}} onClick={toggleMenu}></i>
        )}
      </div>
      {width <= 768 ? (
        <Menu
          showMenu={showMenu}
          setShowMenu={setShowMenu}
          toggleMenu={toggleMenu}
        />
      ) : (
        <></>
      )}
      <div className={showMenu ? "content-blur" : "content"}>
        <div className="header">
          <Header title={title} setModalIsOpen={setModalIsOpen} handleSearchSubmit={handleSearchSubmit} setQuery={setQuery} />
        </div>
        {children}
      </div>
    </div>
  );
}

export default Layer;
