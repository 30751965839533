import React from "react";
import "./index.scss";
import deleteSelectedInvoiceImage from "../../../services/delete-selected-invoice-images";
import { toast } from "react-toastify";
function DeleteMode({
  images,
  doCloseDeleteMode,
  setImages,
  purchace,
  doGetPurchaseById,
}: any) {
  const imagesToDeleteFromDb = () => {
    const imgtodt = images
      .filter((img: any) => img.isSelected && img?.fromDb === true) // Filter the images with isSelected true
      .map((img: any) => img._id); // Map to get the IDs
    return imgtodt;
  };
  const imagesToDeleteFromArray = () => {
    const imgtodt = images
      .filter((img: any) => img.isSelected && img?.fromDb === false) // Filter the images with isSelected true
      .map((img: any) => img._id); // Map to get the IDs
    return imgtodt;
  };
  console.log("imagesToDeleteFromArray", imagesToDeleteFromArray());
  console.log("imagesToDeleteFromDb", imagesToDeleteFromDb());

  const toggleCheck = (currentImage: any) => {
    setImages((prevImages: any) =>
      prevImages.map((image: any) =>
        image._id === currentImage?._id
          ? { ...image, isSelected: !image.isSelected }
          : image
      )
    );
  };
  const doDeleteSelectedImages = () => {
    if (
      imagesToDeleteFromArray().length <= 0 &&
      imagesToDeleteFromDb().length <= 0
    ) {
      toast.info("No images selected for deletion.");
      return;
    }
    if (imagesToDeleteFromDb().length > 0) {
      const body = { imageIds: imagesToDeleteFromDb() };
      deleteSelectedInvoiceImage(purchace?._id, body)
        .then((response) => {
          if (response?.data?.success) {
            toast.success(response?.data?.message);
            doCloseDeleteMode();
            doGetPurchaseById(purchace?._id);
          } else {
            toast.error(response?.data?.message);
          }
        })
        .catch((error) => {
          toast.error("error :", error);
        });
    }
    const listImagesIds = imagesToDeleteFromArray();
    if (imagesToDeleteFromArray().length > 0) {
      console.log("delete --------------", listImagesIds);

      // const idsToDeleteSet = new Set(listImagesIds.map((img: any) => img._id));
      const idsToDeleteSet = new Set(listImagesIds);
      setImages((prevImages: any) =>
        prevImages.filter((image: any) => !idsToDeleteSet.has(image._id))
      );
    }
  };

  console.log("images ====>", images);

  return (
    <div className="delete-mode-high-container">
      <div className="delete-mode-header">
        <span className="invoice-title">Factures</span>
      </div>
      <div className="delete-mode-body">
        <div className="images-uploaded-container" style={{ margin: 10 }}>
          {images?.map((image: any, index: any) => (
            <div
              key={index}
              className={
                image?.isSelected
                  ? "one-image-content-checked"
                  : "one-image-content"
              }
              // onClick={() => openDetail(image)}
            >
              <span className="image-title">{image?.name}</span>
              <div
                className={
                  image?.isSelected ? "box-check-checked" : "box-check"
                }
                onClick={() => toggleCheck(image)}
              ></div>
            </div>
          ))}
        </div>
      </div>
      <div className="delete-mode-footer">
        <div className="stepper-container">
          <div className="one-stepper-inactive" />
          <div className="one-stepper" />
        </div>
        <div className="btns-container">
          <button className="btn-cancel" onClick={() => doCloseDeleteMode()}>
            Annuler
          </button>
          <button className="btn-save" onClick={() => doDeleteSelectedImages()}>
            Confirmer
          </button>
        </div>
      </div>
    </div>
  );
}

export default DeleteMode;
