import { useLayoutEffect, useState } from "react";

export const getImageByName = (productName: string): string => {
    switch (productName) {
      case "Tomato":
        return "./../../../assets/" + productName?.toString() + ".png";
      case "Pimen":
        return "./../../../assets/" + productName?.toString() + ".png";
      case "Olive":
        return "./../../../assets/" + productName?.toString() + ".png";
      case "Potato":
        return "./../../../assets/" + productName?.toString() + ".png";
      case "Octopus":
        return "./../../../assets/" + productName?.toString() + ".png";
  
      default:
        return "./../../../assets/Tomato.png";
    }
  };
  export function formatDateAndTime(inputDate: any) {
    const date = new Date(inputDate);
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0"); // Month is 0-based
    const year = date.getFullYear();
    const hours = String(date.getHours()).padStart(2, "0");
    const minutes = String(date.getMinutes()).padStart(2, "0");
  
    return `${day}/${month}/${year} ${hours}:${minutes}`;
  }
  
  export enum Unit {
    KG = "kilogramme",
    G = "gramme",
    L = "liter",
  }
  
  export enum orderPurchaseStatus {
    pending = "pending",
    completed = "completed",
    cancelled = "cancelled",
  }
  
  export function camelize(str: string) {
    return str
      .replace(/(?:^\w|[A-Z]|\b\w)/g, function (word, index) {
        return index === 0 ? word.toLowerCase() : word.toUpperCase();
      })
      .replace(/\s+/g, "");
  }
  export function capitalizeFirstLetter(string: string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }
  
  export function convertStatus(status: string) {
    const statusMap: any = {
      pending: "en attente d’achat",
      completed: "Achat effectué",
      cancelled: "Achat annulé",
      validated: "Achat validé",
    };
  
    return statusMap[status] || "Statut inconnu";
  }
  
  export function getStatus(status: string) {
    const statusMap: any = {
      pending: { label: "en attente d’achat", value: "pending" },
      completed: { label: "Achat effectué", value: "completed" },
      cancelled: { label: "Achat annulé", value: "cancelled" },
      validated: { label: "Achat validé", value: "validated" },
    };
  
    return statusMap[status] || { label: "Unknown", value: "" };
  }
  
  export function otherStatus(status: any) {
    const statusMap: any = {
      pending: "en attente d’achat",
      completed: "Achat effectué",
      cancelled: "Achat annulé",
      validated: "Achat validé",
    };
  
    const otherStatuses: any = [];
  
    for (const key in statusMap) {
      if (key !== status) {
        otherStatuses.push({ label: statusMap[key], value: key });
      }
    }
    console.log("otherStatuses", otherStatuses);
  
    return otherStatuses;
  }
  
  export function bgColorStatus(status: string) {
    const statusMap: any = {
      pending: "#FFE6A6",
      completed: "#DBF2DA",
      cancelled: "#FFCDCD",
      validated : "#159B7B"
    };
  
    return statusMap[status] || "#fff";
  }
  
  
  export   const sideBarContent = [
    { title: "Acceuil", value: "", route: "home", icon: "homeIcon" },
    {
      title: "Alimentation",
      value: "",
      route: "alimentation",
      icon: "alimentationIcon",
    },
    {
      title: "Bons de commande",
      value: "",
      route: "purchaceOrder",
      icon: "purchaceIcon",
    },
    { title: "Produits", value: "", route: "", icon: "productsIcon" },
    { title: "Magasins", value: "", route: "stores", icon: "storeIcon" },
    { title: "Historique", value: "", route: "history", icon: "history" },
    {
      title: "Fournisseurs",
      value: "",
      route: "providers",
      icon: "providerIcon",
    },
  ];


  function getWindowDimensions() {
    const { innerWidth: width, innerHeight: height } = window;
    return {
      width,
      height,
    };
  }
  
 export function useWindowDimensions() {
    const [windowDimensions, setWindowDimensions] = useState(
      getWindowDimensions()
    );
  
    useLayoutEffect(() => {
      function handleResize() {
        setWindowDimensions(getWindowDimensions());
      }
  
      window.addEventListener("resize", handleResize);
      return () => window.removeEventListener("resize", handleResize);
    }, []);
  
    return windowDimensions;
  }