import axios from "axios";

const api = axios.create({
  baseURL: process.env?.REACT_APP_SERVER_URL,
});

const config = {
  headers: {
    "Content-Type": "application/json",
  },
  timeout: 240000,
};

export const getRequest = (url: string, configArg?: any) =>
  api.get(url, { ...config, ...configArg });

export const postRequest = (url: string, data: any, configArg?: any) =>
  api.post(url, data, { ...config, ...configArg });

export const putRequest = (url: string, data: any) =>
  api.put(url, data, config);

export const deleteRequest = (url: string, data?: any, configArg?: any) =>
  api.request({
    url,
    method: 'delete',
    data,
    ...config,
    ...configArg
  });

export const patchRequest = (url: string, data: any, configArg?: any) =>
  api.patch(url, data, { ...config, ...configArg });
