import React from "react";

import { Routes, Route } from "react-router-dom";
import { BrowserRouter as Router } from "react-router-dom";

import "boxicons/css/boxicons.min.css";
import "react-toastify/dist/ReactToastify.css";
import HomeScreen from "./screens/HomeScreen";
import AchatScreen from "./screens/AchatScreen";
import PurchaceDetails from "./screens/PurchaceDetails";

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<HomeScreen />} />
        <Route path="/achat" element={<AchatScreen />} />
        <Route path="/purchaceDetails/:id" element={<PurchaceDetails />} />

      </Routes>
    </Router>
  );
}

export default App;
