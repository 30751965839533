import { deleteFromLocalStorage } from "../utils/localstorage";
import { LocalStorageEnum } from "../utils/localstorage/LocalStorageEnum";

function isTokenExpired(token: string): boolean {
  try {
    const payload = JSON.parse(atob(token.split(".")[1])); // Decode the JWT payload
    const expirationTime = payload.exp * 1000; // Convert exp to milliseconds
    return expirationTime < Date.now();
  } catch (error) {
    deleteFromLocalStorage(LocalStorageEnum.TOKEN);
    return true; // If the token is malformed, consider it expired
  }
}

export default isTokenExpired;
