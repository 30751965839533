import React, { useEffect, useState } from "react";
import "./index.scss";
import uploadImages from "../../../services/patch-purchase-incoice-images";
import { toast } from "react-toastify";
import ImageInvoiceDetail from "../ImageInvoiceDetail";
import getPurchaceById from "../../../services/get-purchace-by-id";
import Lottie from "react-lottie";
import { defaultOptionsLoading } from "../../../assets/lottie-animations/animationOptions";
import DeleteMode from "../DeleteMode";

interface imageInvoice {
  data: any;
  name: string;
  _id: string;
  isSelected: boolean;
}

function InvoiceModal({ purchace }: any) {
  const [images, setImages] = useState<imageInvoice[]>([]);
  const [displayDetailInvoice, setDisplayDetailInvoice] = useState(false);
  const [imageToDisplay, setImageToDisplay] = useState(null);
  const [currentPurchace, setCurrentPurchace] = useState<any>(null);
  const [loading, setLoading] = useState(false);
  const [deleteMode, setDeleteMode] = useState(false);

  console.log("images", images);

  const handleImageUpload = (event: any) => {
    const files = Array.from(event.target.files);

    const updatedImages = files.map((file: any) => ({
      data: file,
      name: file.name,
      _id: Math.random(), // Using URL.createObjectURL to generate a temporary ID
      isSelected: false,
      file: file,
      fromDb: false,
    }));

    setImages((prevImages: any) => [...prevImages, ...updatedImages]);
  };

  console.log("images", images);

  const doUploadImages = (id: any) => {
    setLoading(true)
    const formData = new FormData();
    images.forEach((image: any) => {
      formData.append("images", image.file); // Appending each file under the key "images"
    });

    uploadImages(purchace?._id, formData)
      .then((response) => {
        setLoading(false)

        if (response?.data?.success) {
          toast.success(response?.data?.message);
        } else {
          toast.error(response?.data?.message);
        }
      })
      .catch((error) => {
        setLoading(false)

        toast.error(error.message);
      });
  };

  const doGetPurchaseById = (id: any) => {
    setLoading(true);
    getPurchaceById(id)
      .then((response: any) => {
        setLoading(false);

        if (response?.data?.success) {
          setCurrentPurchace(response?.data.content);

          // Transform the images to match the imageInvoice interface
          const transformedImages = response?.data.content?.images.map(
            (img: any) => ({
              data: img.data, // Assuming the data is stored here
              name: img.name,
              _id: img._id,
              isSelected: false,
              fromDb: true,
            })
          );

          setImages(transformedImages);
        } else {
          toast.error(response?.data?.message);
        }
      })
      .catch((error) => {
        setLoading(false);
        toast.error("error: " + error.message);
      });
  };
  console.log("currentPurchace", currentPurchace);

  const closeDetailImage = () => {
    setDisplayDetailInvoice(false);
    // doGetPurchaseById(purchace?._id);
  };

  useEffect(() => {
    doGetPurchaseById(purchace?._id);
  }, []);

  const openDetail = (image: any) => {
    setDisplayDetailInvoice(true);
    setImageToDisplay(image);
  };
  const doCloseDeleteMode = () => {
    setDeleteMode(false);
    console.log("images ", images);
    setImages((prevImages) =>
      images.map((image) => {
        return { ...image, isSelected: false };
      })
    );
  };
  console.log("deleteMode", deleteMode);
  const openDeleteMode = () => {
    console.log("tessssssssssssss");

    setDeleteMode(true);
  };

  return (
    <>
      {deleteMode ? (
        <DeleteMode
          images={images}
          doCloseDeleteMode={doCloseDeleteMode}
          setImages={setImages}
          purchace={purchace}
          doGetPurchaseById={doGetPurchaseById}
        />
      ) : (
        <>
          {loading ? (
            <div className="loading-container">
              <Lottie
                options={defaultOptionsLoading}
                height={"60%"}
                width={"30%"}
              />
            </div>
          ) : (
            <>
              {displayDetailInvoice ? (
                <ImageInvoiceDetail
                  displayDetailInvoice={displayDetailInvoice}
                  setDisplayDetailInvoice={setDisplayDetailInvoice}
                  imageToDisplay={imageToDisplay}
                  setImageToDisplay={setImageToDisplay}
                  purchace={currentPurchace}
                  closeDetailImage={closeDetailImage}
                  setImages={setImages}
                  images={images}
                  doGetPurchaseById={doGetPurchaseById}
                />
              ) : (
                <div className="hight-contaier-im">
                  <div className="header-im">
                    <span className="title-style">Facture</span>
                    <i
                      className="bx bx-trash"
                      style={{ fontSize: 26, color: "#CD4443" }}
                      onClick={() => openDeleteMode()}
                    ></i>
                  </div>
                  <div className="body-im">
                    <div className="upload-images-container">
                      <span className="title-upload">Ajouter une facture</span>
                      <div className="input-upload">
                        <div className="input-right-side">
                          <i
                            className="bx bx-image"
                            style={{
                              color: "#787878",
                              marginRight: 5,
                              fontSize: 22,
                            }}
                          ></i>
                          <span style={{ color: "#787878" }}>
                            jpg, png, pdf, doc
                          </span>
                        </div>
                        <div className="input-left-side">
                          <label
                            htmlFor="image-upload"
                            style={{ color: "#159B7B" }}
                          >
                            Upload
                          </label>
                          <input
                            type="file"
                            id="image-upload"
                            style={{ display: "none" }}
                            accept="image/*"
                            multiple
                            onChange={handleImageUpload}
                          />
                          <i
                            className="bx bx-upload"
                            style={{
                              fontSize: 22,
                              marginLeft: 5,
                              color: "#159B7B",
                            }}
                          ></i>
                        </div>
                      </div>
                    </div>
                    <div className="images-uploaded-container">
                      {images?.map((image: any, index: any) => (
                        <div
                          key={index}
                          className="one-image-content"
                          onClick={() => openDetail(image)}
                        >
                          <span>{image?.name}</span>
                        </div>
                      ))}
                    </div>
                  </div>
                  <div className="footer-im">
                    <div className="stepper-container-im">
                      <div className="one-stepper" />
                      <div className="one-stepper-inactive" />
                    </div>
                    <div className="save-btn-container-im">
                      <button
                        className="btn-save-im"
                        onClick={() => doUploadImages(1)}
                      >
                        Sauvegarder
                      </button>
                    </div>
                  </div>
                </div>
              )}
            </>
          )}
        </>
      )}
    </>
  );
}

export default InvoiceModal;
