interface PhoneNumberDetails {
  phoneNumber: string;
}

// Function to extract the phone number based on the country calling code
function extractPhoneNumber(
  fullPhoneNumber: string,
  countryCallingCode: string
): PhoneNumberDetails {
  // Check if the full phone number starts with the country calling code
  if (!fullPhoneNumber.startsWith(countryCallingCode)) {
    return {
      phoneNumber: "",
    };
  }

  // Remove the country calling code from the full phone number
  const phoneNumber = fullPhoneNumber.substring(countryCallingCode.length);

  return {
    phoneNumber,
  };
}

export default extractPhoneNumber;
