import React from "react";
import "./index.scss";
import SearchBar from "../SearchBar";
import { useWindowDimensions } from "../../utils";
function Header({ title, setModalIsOpen, handleSearchSubmit, setQuery }: any) {
  const { height, width } = useWindowDimensions();

  console.log(width);
  const handleSearchSubmitt = () => {
    console.log("yesss");
  };

  return (
    <>
      {width > 768 ? (
        <div className="header-container-x">
          <div className="left-h">
            <span className="title-txt">{title}</span>
          </div>
          <div className="center-h">
            <SearchBar
              handleSearchSubmit={handleSearchSubmit}
              setQuery={setQuery}
            />{" "}
          </div>
          <div className="right-h"></div>
        </div>
      ) : (
        <div className="header-container-x">
          <div className="title-menu-content">
            <div className="menu-container">
              <i className="bx bx-menu" style={{ fontSize: 26 }}></i>
            </div>
            <div className="left-h">
              <span className="title-txt">{title}</span>
            </div>
          </div>

          <div className="center-h">
            <SearchBar
              handleSearchSubmit={handleSearchSubmit}
              setQuery={setQuery}
            />{" "}
          </div>
        </div>
      )}
    </>
  );
}

export default Header;
