import axios from "axios";
import envconf from "../../envConfig";

const api = axios.create({
  baseURL: envconf.INVENTORY_SERVER_URL,
});

const apilb4 = axios.create({
  baseURL: envconf.LB4_SERVER_URL,
});

const config = {
  headers: {
    "Content-Type": "application/json",
  },
  timeout: 240000,
};

export const getRequest = (url: string, configArg?: any) =>
  api.get(url, { ...config, ...configArg });

export const postRequest = (url: string, data: any, configArg?: any) =>
  api.post(url, data, { ...config, ...configArg });

export const putRequest = (url: string, data: any) =>
  api.put(url, data, config);

export const deleteRequest = (url: string, data?: any, configArg?: any) =>
  api.request({
    url,
    method: "delete",
    data,
    ...config,
    ...configArg,
  });

export const patchRequest = (url: string, data: any, configArg?: any) =>
  api.patch(url, data, { ...config, ...configArg });

export const getRequestLb4 = (url: string, configArg?: any) =>
  apilb4.get(url, { ...config, ...configArg });

export const postRequestLb4 = (url: string, data: any, configArg?: any) =>
  apilb4.post(url, data, { ...config, ...configArg });

export const putRequestLb4 = (url: string, data: any) =>
  apilb4.put(url, data, config);

export const deleteRequestLb4 = (url: string, data?: any, configArg?: any) =>
  apilb4.request({
    url,
    method: "delete",
    data,
    ...config,
    ...configArg,
  });

export const patchRequestLb4 = (url: string, data: any, configArg?: any) =>
  apilb4.patch(url, data, { ...config, ...configArg });
