// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.image-invoice-container {
  height: 100%;
  width: 100%;
}
.image-invoice-container .image-invoice-header {
  height: 15%;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.image-invoice-container .image-invoice-header .deletion-confirm-container {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  flex: 1 1;
}
.image-invoice-container .image-invoice-header .deletion-confirm-container .cancel-btn {
  height: 35px;
  width: 70px;
  border-width: 0px;
  background-color: #e4e4e4;
  border-radius: 4px;
  font-weight: 600;
}
.image-invoice-container .image-invoice-header .deletion-confirm-container .confirm-btn {
  height: 35px;
  width: 70px;
  border-width: 0px;
  margin-left: 15px;
  background-color: #cd4443;
  border-radius: 4px;
  font-weight: 600;
}
.image-invoice-container .image-invoice-body {
  height: 70%;
  max-height: 70%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: auto;
}
.image-invoice-container .image-invoice-body .img-invoice {
  max-height: 100%;
  max-width: 100%;
  object-fit: contain;
}
.image-invoice-container .image-invoice-footer {
  height: 15%;
  width: 100%;
}

.loading-container {
  height: 100%;
  width: 100%;
}`, "",{"version":3,"sources":["webpack://./src/components/Modals/ImageInvoiceDetail/index.scss"],"names":[],"mappings":"AAAA;EACE,YAAA;EACA,WAAA;AACF;AAAE;EACE,WAAA;EACA,WAAA;EACA,aAAA;EACA,mBAAA;EACA,8BAAA;AAEJ;AADI;EACE,aAAA;EACA,mBAAA;EACA,yBAAA;EACA,SAAA;AAGN;AAFM;EACE,YAAA;EACA,WAAA;EACA,iBAAA;EACA,yBAAA;EACA,kBAAA;EACA,gBAAA;AAIR;AAFM;EACE,YAAA;EACA,WAAA;EACA,iBAAA;EACA,iBAAA;EACA,yBAAA;EACA,kBAAA;EACA,gBAAA;AAIR;AAAE;EACE,WAAA;EACA,eAAA;EACA,WAAA;EACA,aAAA;EACA,uBAAA;EACA,mBAAA;EACA,cAAA;AAEJ;AADI;EACE,gBAAA;EACA,eAAA;EACA,mBAAA;AAGN;AAEE;EACE,WAAA;EACA,WAAA;AAAJ;;AAGA;EACE,YAAA;EACA,WAAA;AAAF","sourcesContent":[".image-invoice-container {\n  height: 100%;\n  width: 100%;\n  .image-invoice-header {\n    height: 15%;\n    width: 100%;\n    display: flex;\n    flex-direction: row;\n    justify-content: space-between;\n    .deletion-confirm-container {\n      display: flex;\n      flex-direction: row;\n      justify-content: flex-end;\n      flex: 1;\n      .cancel-btn {\n        height: 35px;\n        width: 70px;\n        border-width: 0px;\n        background-color: #e4e4e4;\n        border-radius: 4px;\n        font-weight: 600;\n      }\n      .confirm-btn {\n        height: 35px;\n        width: 70px;\n        border-width: 0px;\n        margin-left: 15px;\n        background-color: #cd4443;\n        border-radius: 4px;\n        font-weight: 600;\n      }\n    }\n  }\n  .image-invoice-body {\n    height: 70%;\n    max-height: 70%;\n    width: 100%;\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    overflow: auto;\n    .img-invoice {\n      max-height: 100%;\n      max-width: 100%;\n      object-fit: contain;\n      // background-color: #cd4443;\n    }\n  }\n\n  .image-invoice-footer {\n    height: 15%;\n    width: 100%;\n  }\n}\n.loading-container {\n  height: 100%;\n  width: 100%;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
