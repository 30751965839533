import CryptoJS from "crypto-js";
import envconf from "../../envConfig";

const MASTER_KEY = envconf.SECRET_KEY || "PASSKEY000!";

const encryptWithKey = (value: string, key: string): string => {
  return CryptoJS.AES.encrypt(value, key).toString();
};

const decryptWithKey = (encryptedValue: string, key: string): string => {
  const bytes = CryptoJS.AES.decrypt(encryptedValue, key);
  return bytes.toString(CryptoJS.enc.Utf8);
};

const setLocalStorage = (key: string, value: string) => {
  const encryptedValue = encryptWithKey(value, MASTER_KEY);

  localStorage.setItem(key, encryptedValue);
};

const getFromLocalStorage = (key: string): string | null => {
  const encryptedValue = localStorage.getItem(key);

  if (encryptedValue) {
    const decryptedValue = decryptWithKey(encryptedValue, MASTER_KEY);
    return decryptedValue;
  }

  return null;
};

const deleteFromLocalStorage = (key: string): void => {
  localStorage.removeItem(key);
};

export { setLocalStorage, getFromLocalStorage, deleteFromLocalStorage };
