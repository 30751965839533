import React from "react";
import { Navigate } from "react-router-dom";
import { getFromLocalStorage } from "../utils/localstorage";
import isTokenExpired from "../helpers/isTokenExpired";
import { toast } from "react-toastify";
import { LocalStorageEnum } from "../utils/localstorage/LocalStorageEnum";
import envconf from "../envConfig";

interface ProtectedOutProps {
  children: JSX.Element;
}

const ProtectedOut: React.FC<ProtectedOutProps> = ({ children }) => {
  if (envconf.IS_DEV) {
    return children;
  }

  const token = getFromLocalStorage(LocalStorageEnum.TOKEN);

  // If there's no token or the token is invalid (like "xxx") or expired, redirect to login
  if (isTokenExpired(token || "") || !token) {
    toast.error("Session expired, you will be disconnected !");
    return <Navigate to="/login" replace />;
  }

  // If the token is valid and not expired, render the protected route
  return children;
};

export default ProtectedOut;
