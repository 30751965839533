import React, { useEffect, useState } from "react";
import Layer from "../../components/Layer";
import "./index.scss";
import ListOrders from "../../components/ListOrders";
import { toast } from "react-toastify";
import getAllPurchaces from "../../services/get-list-purchace";
import updateOrderStatus from "../../services/update-purchace-status";
import searchPurchase from "../../services/post-search-purchase";
import Lottie from "react-lottie";
import { defaultOptionsLoading } from "../../assets/lottie-animations/animationOptions";

function AchatScreen() {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [showMenu, setShowMenu] = useState(false);
  const [orderList, setOrderList] = useState<any>([]);
  const [limit, setLimit] = useState(10);
  const [skip, setSkip] = useState(0);
  const [loading, setLoading] = useState(false);
  const [query, setQuery] = useState("");

  // State to manage which dropdown is open for each order
  const [optionsShown, setOptionsShown] = useState<{ [key: string]: boolean }>(
    {}
  );

  const doUpdateStatus = (data: any, id: any) => {
    updateOrderStatus(data, id)
      .then((response: any) => {
        if (response?.data?.status === 500) {
          toast.error("internal server error !");
          return;
        }
        if (!response?.data?.success) {
          toast.error(response?.data?.message);
          return;
        }
        if (response?.data?.success) {
          doGetInitPurchaces();
          setSkip(0);
          setLimit(10);
        }
      })
      .catch((error: any) => {
        toast?.error(error);
      });
  };

  useEffect(() => {
    if (!Boolean(query)) {
      doGetInitPurchaces();
    }
  }, [query]);

  useEffect(() => {
    function handleResize() {
      const isMobileView = window.innerWidth <= 768;
      setShowMenu(isMobileView);
    }

    window.addEventListener("resize", handleResize);
    handleResize();

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const doGetAllPurchaces = (limit: any, skip: any) => {
    setLoading(true);
    if (limit !== undefined && skip !== undefined) {
      setLoading(true);
      getAllPurchaces(limit, skip)
        .then((response) => {
          setLoading(false);

          if (response?.data?.status === 500) {
            toast.error("Internal server error !");
            return;
          }
          if (!response?.data?.success) {
            toast.error(response?.data?.message);
            return;
          }
          if (response?.data?.success) {
            setOrderList([
              ...orderList,
              ...response?.data?.content?.purchaseOrders,
            ]);
          }
        })
        .catch((error: any) => {
          setLoading(false);
          console.log(error);
        });
    }
  };
  const doGetInitPurchaces = () => {
    setLoading(true);
    getAllPurchaces(10, 0)
      .then((response) => {
        setLoading(false);

        if (response?.data?.status === 500) {
          toast.error("Internal server error !");
          return;
        }
        if (!response?.data?.success) {
          toast.error(response?.data?.message);
          return;
        }
        if (response?.data?.success) {
          setOrderList(response?.data?.content?.purchaseOrders);
        }
      })
      .catch((error) => {
        setLoading(false);
        console.log(error);
      });
  };

  const loadMore = () => {
    let newSkip = skip + limit;
    doGetAllPurchaces(limit, newSkip);
    setSkip(newSkip);
  };

  useEffect(() => {
    doGetInitPurchaces();
  }, []);

  const toggleOptions = (orderId: string) => {
    setOptionsShown((prevState) => ({
      ...prevState,
      [orderId]: !prevState[orderId],
    }));
  };
  const doHandleSearchPurchase = (query: any) => {
    searchPurchase(query)
      .then((response) => {
        if (response?.data?.success) {
          setOrderList(response?.data.content);
        } else {
          toast?.error(response?.data?.message);
        }
      })
      .catch((error) => {
        toast.error(error);
      });
  };

  return (
    <Layer
      title={"Achat"}
      modalIsOpen={modalIsOpen}
      setModalIsOpen={setModalIsOpen}
      handleSearchSubmit={doHandleSearchPurchase}
      setQuery={setQuery}
    >
      {loading ? (
        // @ts-ignore

        <Lottie
          options={defaultOptionsLoading}
          height={"200px"}
          width={"200px"}
        />
      ) : (
        <ListOrders
          loadMore={loadMore}
          orderList={orderList}
          doUpdateStatus={doUpdateStatus}
        />
      )}
    </Layer>
  );
}

export default AchatScreen;
