import React from "react";
import { Field, Formik } from "formik";
import "./index.scss";
import { useWindowDimensions } from "../../utils";
function SearchBar({
  handleSearchSubmit = () => {},
  setQuery = () => {},
}: any) {
  const { height, width } = useWindowDimensions();

  const handleChangeQueryy = (e: any, setFieldValue: any) => {
    setFieldValue("query", e.target.value);
    setQuery(e.target.value);
  };

  return (
    <div className="search-bar-container">
      <Formik initialValues={{ query: "" }} onSubmit={handleSearchSubmit}>
        {({ handleSubmit, setFieldValue }: any) => (
          <form onSubmit={handleSubmit}>
            <div
              style={{
                minWidth: 150,
                width: width > 768 ? "35vw" : "80vw",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <div className="input-wrapper">
                <Field
                  name="query"
                  placeholder="search"
                  className="input-search-style"
                  onChange={(e: any) => handleChangeQueryy(e, setFieldValue)}
                />
                <i
                  onClick={handleSubmit}
                  className="bx bx-search"
                  style={{ fontSize: 28, marginRight: 10 }}
                ></i>
              </div>
            </div>
          </form>
        )}
      </Formik>
    </div>
  );
}

export default SearchBar;
