import { putRequest } from "../utils/axios";

type DataFromAxiosType = {
  data: {
    success: boolean;
    message: string;
    status: number;
    content: Record<string, any>;
  };
};

async function editProdPurchase(
  orderId: any,
  productId: any,
  bodyData: any
): Promise<DataFromAxiosType> {
  try {
    const { data }: DataFromAxiosType = await putRequest(
      `/purchaseOrder/purchase-order/${orderId}/product/${productId}`,
      bodyData
    );

    if (!data.success) {
      throw new Error(data?.message);
    }

    return {
      data,
    };
  } catch (error: any) {
    const returnedData: DataFromAxiosType = {
      data: {
        success: false,
        message: error?.response?.data?.message ?? "",
        status: error?.response?.status ?? 500,
        content: [],
      },
    };
    return returnedData;
  }
}

export default editProdPurchase;
