import animationDataLoading from "./loading.json";

import FourDotJson from "./threedots.json";

export const defaultOptionsLoading = {
  loop: true,
  autoplay: true,
  animationData: animationDataLoading,
  rendererSettings: {
    preserveAspectRatio: "xMidYMid slice",
  },
};

export const FourDotsLoading = {
  loop: true,
  autoplay: true,
  animationData: FourDotJson,
  rendererSettings: {
    preserveAspectRatio: "xMidYMid slice",
  },
};
