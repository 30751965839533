// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.menu-wrapper {
  position: absolute;
  z-index: 1000;
}
.menu-wrapper .logo-container {
  height: 25vh;
  width: 100%;
}
.menu-wrapper .logo-container .close-container {
  display: flex;
  justify-content: flex-end;
  width: 100%;
  align-items: flex-end;
}
.menu-wrapper .logo-container .img-container {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  width: 80%;
  height: 80%;
}

.menu-icon {
  font-size: 24px;
  cursor: pointer;
  background: none;
  border: none;
}

.menu-container {
  position: absolute;
  left: 0;
  width: 60vw;
  height: 100vw;
  min-height: 100vh;
  background-color: white;
  border: 1px solid #ccc;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  opacity: 0;
  transform: translateX(-20px);
  transition: all 0.3s ease;
  pointer-events: none;
  z-index: 1000;
}

.menu-container.open {
  opacity: 1;
  transform: translateX(0);
  pointer-events: auto;
}

.menu-container ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.menu-container li {
  padding: 10px;
  border-bottom: 1px solid #ccc;
}

.menu-container li:last-child {
  border-bottom: none;
}`, "",{"version":3,"sources":["webpack://./src/components/Menu/index.scss"],"names":[],"mappings":"AAAA;EACE,kBAAA;EACA,aAAA;AACF;AACE;EACE,YAAA;EACA,WAAA;AACJ;AAAI;EACE,aAAA;EACA,yBAAA;EACA,WAAA;EACA,qBAAA;AAEN;AAAI;EACE,aAAA;EACA,uBAAA;EACA,uBAAA;EACA,UAAA;EACA,WAAA;AAEN;;AAGA;EACE,eAAA;EACA,eAAA;EACA,gBAAA;EACA,YAAA;AAAF;;AAGA;EACE,kBAAA;EACA,OAAA;EACA,WAAA;EACA,aAAA;EACA,iBAAA;EACA,uBAAA;EACA,sBAAA;EACA,wCAAA;EACA,UAAA;EACA,4BAAA;EACA,yBAAA;EACA,oBAAA;EACA,aAAA;AAAF;;AAGA;EACE,UAAA;EACA,wBAAA;EACA,oBAAA;AAAF;;AAGA;EACE,qBAAA;EACA,SAAA;EACA,UAAA;AAAF;;AAGA;EACE,aAAA;EACA,6BAAA;AAAF;;AAGA;EACE,mBAAA;AAAF","sourcesContent":[".menu-wrapper {\n  position: absolute;\n  z-index: 1000;\n  \n  .logo-container {\n    height: 25vh;\n    width: 100%;\n    .close-container {\n      display: flex;\n      justify-content: flex-end;\n      width: 100%;\n      align-items: flex-end;\n    }\n    .img-container {\n      display: flex;\n      align-items: flex-start;\n      justify-content: center;\n      width: 80%;\n      height: 80%;\n    }\n  }\n}\n\n.menu-icon {\n  font-size: 24px;\n  cursor: pointer;\n  background: none;\n  border: none;\n}\n\n.menu-container {\n  position: absolute;\n  left: 0;\n  width: 60vw;\n  height: 100vw;\n  min-height: 100vh;\n  background-color: white;\n  border: 1px solid #ccc;\n  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);\n  opacity: 0;\n  transform: translateX(-20px);\n  transition: all 0.3s ease;\n  pointer-events: none;\n  z-index: 1000;\n}\n\n.menu-container.open {\n  opacity: 1;\n  transform: translateX(0);\n  pointer-events: auto;\n}\n\n.menu-container ul {\n  list-style-type: none;\n  margin: 0;\n  padding: 0;\n}\n\n.menu-container li {\n  padding: 10px;\n  border-bottom: 1px solid #ccc;\n}\n\n.menu-container li:last-child {\n  border-bottom: none;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
