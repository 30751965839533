import React from "react";
import "./index.scss";
import { useLocation, useNavigate } from "react-router-dom";
function SideBar() {
  const sideBarElements = [
    { title: "providers", value: "", route: "/", icon: "providerIcon" },
    { title: "achat", value: "", route: "/achat", icon: "achatIcon" },
  ];
  const location = useLocation();

  const navigate = useNavigate();
  return (
    <div className="container-sb">
      <div className="logo-container-sb">
        <img src="./../../assets/achatAppIcon.png" alt="logo" />
        <div className="title-container">
          <span className="title-style">Pizzaie</span>
          <span className="title-style">Achat</span>
        </div>
      </div>
      <div className="elements-sb">
        {sideBarElements?.map((element, index) => (
          <button
            key={index}
            className={`${
              location.pathname === element.route
                ? "btn-element-sb-active"
                : "btn-element-sb"
            }`}
            onClick={() => navigate(element?.route)}
          >
            <img
              src={`./../../assets/providerIcon.png`}
              alt="storeIcon"
              className="img-btn-sb"
            />
            {element?.title}
          </button>
        ))}
      </div>
    </div>
  );
}

export default SideBar;
